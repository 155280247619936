const ImgData = [
    // 头像的数据
    { url: require("@/assets/Img/portrait/10001.png") },
    { url: require("@/assets/Img/portrait/10002.png") },
    { url: require("@/assets/Img/portrait/10003.png") },
    { url: require("@/assets/Img/portrait/10004.png") },
    { url: require("@/assets/Img/portrait/10005.png") },
    { url: require("@/assets/Img/portrait/10006.png") },
    { url: require("@/assets/Img/portrait/10007.png") },
    { url: require("@/assets/Img/portrait/10008.png") },
    { url: require("@/assets/Img/portrait/10009.png") },
    { url: require("@/assets/Img/portrait/10010.png") },
    { url: require("@/assets/Img/portrait/10011.png") },
    { url: require("@/assets/Img/portrait/10012.png") },
    { url: require("@/assets/Img/portrait/10013.png") },
    { url: require("@/assets/Img/portrait/10014.png") },
    { url: require("@/assets/Img/portrait/10015.png") },
    { url: require("@/assets/Img/portrait/10016.png") },
    { url: require("@/assets/Img/portrait/10017.png") },
    { url: require("@/assets/Img/portrait/10018.png") },
    { url: require("@/assets/Img/portrait/10019.png") },
    { url: require("@/assets/Img/portrait/10020.png") },
    { url: require("@/assets/Img/portrait/10021.png") },
    { url: require("@/assets/Img/portrait/10022.png") },
    { url: require("@/assets/Img/portrait/10023.png") },
    { url: require("@/assets/Img/portrait/10024.png") },
    { url: require("@/assets/Img/portrait/10025.png") },
    { url: require("@/assets/Img/portrait/10026.png") },
    { url: require("@/assets/Img/portrait/10027.png") },
    { url: require("@/assets/Img/portrait/10028.png") },
    { url: require("@/assets/Img/portrait/10029.png") },
    { url: require("@/assets/Img/portrait/10030.png") },
    { url: require("@/assets/Img/portrait/10031.png") },
    { url: require("@/assets/Img/portrait/10032.png") },
    { url: require("@/assets/Img/portrait/10033.png") },
    { url: require("@/assets/Img/portrait/10034.png") },
    { url: require("@/assets/Img/portrait/10035.png") },
    { url: require("@/assets/Img/portrait/10036.png") },
    { url: require("@/assets/Img/portrait/10037.png") },
    { url: require("@/assets/Img/portrait/10038.png") },
    { url: require("@/assets/Img/portrait/10039.png") },
    { url: require("@/assets/Img/portrait/10040.png") },
    { url: require("@/assets/Img/portrait/10041.png") },
    { url: require("@/assets/Img/portrait/10042.png") },
    { url: require("@/assets/Img/portrait/10043.png") },
    { url: require("@/assets/Img/portrait/10044.png") },
    { url: require("@/assets/Img/portrait/10045.png") },
]
export default ImgData
