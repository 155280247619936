<template>
  <div id="Portrait" class="wrap">
    <van-nav-bar :fixed="true" placeholder :title="$t('Personal.portrait.title')" left-arrow
      @click-left="onClickLeft" />
    <div class="header">
      <van-uploader :after-read="afterRead">
        <div class="avatar_wrap">
          <van-image round class="img" :src="getImage(DataArr.url)" />
          <div class="icon_photo">
            <img :src="require('@/assets/icon/photos.png')" alt="">
          </div>
        </div>
      </van-uploader>
      <span class="button" @click="SubmitEvent()">{{
      $t("Personal.portrait.setNow")
    }}</span>
    </div>
    <div class="content">
      <div class="content_item" v-for="index in 19" :key="index">
        <van-image class="img" round @click="ImgEvent('/uploads/system/userimg/userimg_' + index + '.png')" :src="verConfig.url + 'uploads/system/userimg/userimg_' + index + '.png'
      " />
      </div>
    </div>
  </div>
</template>

<script>
import ImgData from "@/utils/ImgData";
import { editAvatar } from "@/api/use";
import { $upload, $post } from "@/utils/request.js";
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
  Uploader,
} from "vant";

export default {
  name: "Portrait",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      DataArr: {
        url: "",
        icon: "", // 传递给后台的
        flag: false
      },
      ImgArr: ImgData, // img图片资源
      userInfo: {},
      avatar: ""
    };
  },
  methods: {
    getUserInfo() {
      this.userInfo = JSON.parse(this.$cookie.get("userInfo"));
      this.$nextTick(() => {
        if (this.userInfo.userimg) {
          this.DataArr.url = this.userInfo.userimg;
          this.DataArr.flag = true
        } else {
          this.DataArr.url = require("@/assets/Img/myImg/head.png");
          this.DataArr.flag = false
        }
      })
    },
    imgUrlToFile(url) {
      var self = this;
      var imgLink = url;
      var tempImage = new Image();
      tempImage.onload = function () {
        var base64 = self.getBase64Image(tempImage);
        var imgblob = self.base64toBlob(base64);
        var filename = self.getFileName(self.ruleForm.coverUrl);
        self.ruleForm.coverFile = self.blobToFile(imgblob, filename);
      };
      tempImage.src = imgLink;
    },
    async afterRead(event) {
      event.status = 'uploading';
      event.message = this.$t("upload.uploading");
      this.DataArr.url = event.content;
      const formdata = new FormData();
      try {
        const ImgData = await this.compressImg(event.file);
        const fileData = new File([ImgData], event.file.name, { type: ImgData.type })
        formdata.append("image", fileData);
      } catch (error) {
        formdata.append("image", event.file);
      }
      $upload({ url: "/users/upload", data: formdata })
        .then((res) => {
          if (res.ret === 1) {
            event.status = 'success';
            event.message = '';
            this.imgFile = res.data.path
            // this.formData.card_front = res.data.path;
          } else {
            event.status = 'failed';
            event.message = this.$t("upload.failed");
            Toast(this.$t("upload.failed"))
            // this.$toast(this.$t("uploadErr"));
          }
        })
        .catch((err) => {
          event.status = 'failed';
          event.message = this.$t("upload.failed");
          Toast(this.$t("upload.failed"))
          // this.$toast(this.$t("uploadErr"));
        });
    },
    // 压缩图片
    async compressImg(file) {
      const img = await this.readImg(file);
      const type = img.type;
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const { width: originWidth, height: originHeight } = img
        // 最大尺寸限制
        const maxWidth = 1000
        const maxHeight = 1000
        // 目标尺寸
        let targetWidth = originWidth
        let targetHeight = originHeight
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > 1) {
            //  宽图片
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
          } else {
            // 高图片
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
          }
        }
        canvas.width = targetWidth
        canvas.height = targetHeight
        context.clearRect(0, 0, targetWidth, targetHeight)
        // 图片绘制
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        canvas.toBlob(function (blob) {
          resolve(blob)
        }, type || 'image/png')
      })
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 图片的点击事件
    ImgEvent(url) {
      this.DataArr.icon = url;
      this.DataArr.url = url;
      this.DataArr.flag = false
      this.imgFile = url;
    },
    async SubmitEvent() {
      try {
        const res = await editAvatar({ userimg: this.imgFile });
        Toast(res.msg)
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } catch (error) {
        Toast(error.msg)
      }
    },
    getImage(url) {
      const reg = new RegExp("http");
      const dataReg = new RegExp("data:")
      if (reg.test(url)) {
        return url
      }
      if (dataReg.test(url)) {
        return url
      }
      return this.verConfig.url + url
    }
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
#Portrait {
  width: 100%;
  height: 100%;

  /deep/ .van-nav-bar__content {
    background-color: #ff0000;

    .van-icon-arrow-left {
      color: #fff;
    }

    .van-nav-bar__title {
      color: #fff;
    }
  }

  .header-One {
    opacity: 0;
    width: 100%;
    height: 7.8rem;
  }

  .header {
    position: fixed;
    width: 100%;
    height: 350px;
    z-index: 1;
    background: var(--nav-background, red);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .avatar_wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      @size: 120px;

      .img {
        width: @size;
        height: @size;
      }

      .icon_photo {
        position: absolute;
        top: 0;
        left: 0;
        width: @size;
        height: @size;
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
          width: calc(@size / 1.5);
          height: calc(@size / 1.5);
          // opacity: .6;
        }
      }
    }

    img {
      border-radius: 50px;
    }

    .button {
      border: 1px solid #fff;
      border-radius: 10px;
      color: #fff;
      font-size: 28px;
      padding: 10px 20px;
      margin-top: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 350px;

    &_item {
      width: 20%;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      .img {
        width: 96px;
        height: 96px;
      }
    }
  }
}
</style>